function getHtmlElement(htmlElement, name, obj, printWithEveny = true, translation, printOrgNameAndBrand = true) {
  try {
    if (htmlElement != null) {
      var doc = htmlElement.cloneNode(true);

      var d = new Date();
      var date = d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
      //Build an array containing Customer records.
      var customers = new Array();

      if (printOrgNameAndBrand) {
        customers.push([obj['OrgLevel'].translation, obj['OrgName'].value]);
      }
      customers.push([translation.reportname + ':', name]);

      if (printOrgNameAndBrand && obj['ActiveModalClass']) {
        customers.push([translation.brand + ':', obj['ActiveModalClass'].value]);
      }
      customers.push([translation.daterange + ':', obj['StartDate'].value + ' - ' + obj['EndDate'].value]);
      if (printWithEveny) {
        customers.push([translation.eventtype + ':', obj['Event'].value]);
      }
      customers.push([translation.reportdate + ':', date]);
      customers.push(['', '']);
      //Create a HTML Table element.
      var table = document.createElement('TABLE');
      table.border = '1';

      //Get the count of columns.
      var columnCount = customers[0].length;

      //Add the header row.
      var row = table.insertRow(-1);
      for (var i = 0; i < columnCount; i++) {
        var headerCell = document.createElement('TH');
        headerCell.innerHTML = customers[0][i];
        row.appendChild(headerCell);
      }

      //Add the data rows.
      for (var i = 1; i < customers.length; i++) {
        row = table.insertRow(-1);
        for (var j = 0; j < columnCount; j++) {
          var cell = row.insertCell(-1);
          cell.innerHTML = customers[i][j];
        }
      }

      var d = doc.getElementsByClassName('pspan');

      var i;
      for (i = 0; i < d.length; i++) {
        d[i].innerHTML = '';
        d[i].innerText = '';
        d[i].outerText = '';

        if (d[i] && d[i].childNodes && d[i].childNodes.length > 0) {
          d[i].childNodes[0].nodeValue = '';
        }
      }

      doc.insertBefore(table, doc.firstChild);
      return doc;
    }
  } catch (e) {}
}
